import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GenericDataService } from 'src/app/services/generic.service';
import { SharedInfo } from 'src/app/services/shared-info';
import { UserService } from 'src/app/services/user-service';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { SignatureModelType }from '../../enums/sig-enums';


let startDate: Date = new Date();
let endDate: Date = new Date();

@Injectable()
export class DocumentService extends GenericDataService {

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    public http: HttpClient) {
    super('PublicDocument', 
      { 
        // startDate: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
        // endDate:  new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0),
      }, sharedInfo, userService, http, { add: true, fetch: true, tableMode : true });

    this.routerTitle = 'Documentos';
  }

  groupBy (xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  getColums() {
    return [
       
      { columnDef: 'dateUploaded', header: 'Data Envio', cell: (row) => `${row.dateUploaded}` },
      { columnDef: 'datePublish', header: 'Data da Publicação', cell: (row) => `${row.datePublish}` },
      { columnDef: 'name', header: 'Nome', cell: (row) => `${row.name}` },
    

    ];;
  }

  downloadfileSignedFromLocalAPI(localFileName: string) {
    const headers = new Headers();
    headers.append('responseType', 'arraybuffer');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'Accept': 'application/pdf'
      }),

      responseType: 'blob'
    };


    return this.http.get(this.sharedInfo.localAPIurl + localFileName, { responseType: 'blob' });
  }



  getData(filterData): Observable<any> {
    return this.http
      .put<any>(this.sharedInfo.serviceUrl + '/publicDocument/getdata', filterData);
  }


  getDocumentPendingSignature(userId, method): Observable<any> {
    return this.http
      .get<any>(this.sharedInfo.serviceUrl + `/publicDocument/getDocumentPendingSignature?userId=${userId}&method=${method}`);
  }

  getSignatureModels(userId, type: SignatureModelType): Observable<any> {
    return this.http.get<any>(this.sharedInfo.serviceUrl + '/sign/getSignatureModels/' + userId + '/' + type);
  }

  addSignatureModels(data): Observable<any> {
    return this.http.post<any>(this.sharedInfo.serviceUrl + '/sign/addSignatureModel', data);
  }

  deleteSignatureModel(id): Observable<any> {
    return this.http.delete<any>(this.sharedInfo.serviceUrl + '/sign/deleteSignatureModel/' + id);
  }

  getUsersResume(strFind) {

    if (strFind === '') {
      return of([]);
    }

    return this.http
      .get<any>(this.sharedInfo.authUrl + '/users/getResume?strFind=' + strFind);

  }

  getUserByToken() : Promise<any>  {

    return new Promise((resolve, reject) => {
      this.http.get<any>(this.sharedInfo.authUrl + '/user/getByToken').subscribe(resp=>{
        resolve(resp);
      });
    });

  }

  getServerDate() : Promise<any>  {

    return new Promise((resolve, reject) => {
      this.http.get<any>(this.sharedInfo.serviceUrl + '/util/getServerDate').subscribe(resp=>{
        resolve(resp);
      });
    });

  }

  getPortalSignDetails(id: string, token?: string): Observable<any> {

    var url = this.sharedInfo.serviceUrl;

    if(token) {
      url = url + '/publicDocument/getByToken';
    } else {
      url = url + '/publicDocument/get/' + id;
    }

    return this.http
      .get<any>(url);
  }


  getPortalSignDetails_ByToken(token: string): Observable<any> {

    //Mudar token para garantir que o interceptor repasse.
    this.userService.publicTokenData = token;

    return this.http
      .get<any>(this.sharedInfo.serviceUrl + '/publicDocument/getByToken');
  }


  insertUpdatePortalSign(data): Observable<any> {
    let roteUrl = '/docs/insert';
    if (data.id) {
      roteUrl = '/docs/update';
    }

    return this.http
      .post<any>(this.sharedInfo.serviceUrl + roteUrl, { mainData: data });
  }


  signDocumentOnLine(userId, documentId, signatureId, signatureModelId, imageData = '', method : any = null): Observable<any> {
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + '/PublicDocument/signFile',
        {
          userId: userId,
          documentId: documentId,
          signatureId: signatureId,
          signatureModelId: signatureModelId,
          imageData: imageData,
          method: method
        });
  }

  signDocumentOnLineA1(command): Observable<any> {
    return this.http
      .post<any>(this.sharedInfo.signUrl + '/Signature/PublicDocument/signFileA1', command);
  }


  publishDocumentOnLine(documentId): Observable<any> {
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + '/PublicDocument/publish',
        {
          documentId: documentId,
        });
  }

  insertSigner(signer): Observable<any> {
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + '/PublicDocument/insertSigner', signer);
  }

  deleteSigner(publicDocumentId, id): Observable<any> {
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + '/PublicDocument/deleteSigner', { publicDocumentId: publicDocumentId, id: id });
  }

  updateSignerFields(data): Observable<any> {
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + '/PublicDocument/updateSignerFields', data);
  }

  deleteDocumentOnLine(documentId): Observable<any> {
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + '/PublicDocument/delete',
        {
          id: documentId,
        });
  }

  sendDocumentFromEmail(documentId, mailTo, subject, message): Observable<any> {
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + '/PublicDocument/sendEmail',
        {
          documentId: documentId,
          mailTo: mailTo,
          subject: subject,
          message: message,
          mailFrom: this.userService.userLoged.email
        });
  }

  requestSignatureFromEmail(documentId, signatureId, mailTo, subject, message): Observable<any> {
    return this.http
      .post<any>(this.sharedInfo.serviceUrl + '/PublicDocument/sendRequestSignatureEmail',
        {
          signatureId: signatureId,
          documentId: documentId,
          mailTo: mailTo,
          subject: subject,
          message: message
        });
  }

}
