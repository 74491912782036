import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AdComponent } from 'src/app/models/ad.component';
import { DomainNotification } from 'src/app/models/domainNotification';
import { UserResumeViewModel } from 'src/app/models/user-model';
import { SharedInfo } from 'src/app/services/shared-info';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import Swal from 'sweetalert2';
import { DocumentService } from '../document.service';
import { methodOptions, PrepareDocumentMode, SignatureMethod } from 'src/app/dashboard-sign/models/portalSign-models'
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { UserService } from 'src/app/services/user-service';
import { SignatureModelType } from 'src/app/dashboard-sign/enums/sig-enums';
import { SignatureModelViewModel } from '../document.models';

@Component({
  selector: 'app-add-edit-document',
  templateUrl: './add-edit-document.component.html',
  styleUrls: ['./add-edit-document.component.scss'],
  animations: [SharedAnimations]
})
export class AddEditDocumentComponent  implements AfterViewInit, OnInit {

  @Output()
  public onCancelClick = new EventEmitter<any>();
  @Output()
  public onSaved = new EventEmitter<any>();


  @ViewChild('fileInput') fileInput;
  @ViewChild('txtSignerUser') txtSignerUser;

  public file: any;
  public fileName: string;
  public fileSize: number;

  public progress: number;
  public message: string;

  public formGroug: FormGroup;
  formErrors: DomainNotification[] = [];

  signerUser: UserResumeViewModel = new UserResumeViewModel();
  signers: any[] = [];

  prepareMode = 1;

  methodOptions = methodOptions;
  method: SignatureMethod = SignatureMethod.DigitalCertificate;

  imagesUrl: string;
  signatureModels: SignatureModelViewModel[] = [];
  selectedSignatureModel: SignatureModelViewModel;
  
  insertProtocolPage = true;

  loading = false;


  subjectUsers: Subject<any> = new Subject();
  isLoadingUsers = false;
  filteredUsers: any[] = [];
  
  MatcherName = { isErrorState: () => { return this.formErrors.find(i=>i.key == 'Name'); } };
  MatcherFirstDateOfTask = { isErrorState: () => { return this.formErrors.find(i=>i.key == 'FirstDateOfTask'); } };

  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dataService: DocumentService,
    public deviceService: DeviceDetectorService,
    public sharedInfo: SharedInfo,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private dialog: MatDialog,
    public userService: UserService) {

      this.formGroug = this.formBuilder.group({
        file: [null],
        fileName: [''],
        userIdSign: [''],
      });

      this.imagesUrl = this.dataService.sharedInfo.serviceUrl + '/images/signatures/' + this.userService.userLoged.id;

  }
 


  ngOnInit(){

    this.subjectUsers
    .pipe(debounceTime(800))
    .subscribe((value) => {
      if (value.length >= 2) {
        this.isLoadingUsers = true;
        this.dataService.getUsersResume(value).subscribe(ret => {
          this.isLoadingUsers = false;
          this.filteredUsers = ret
        }, err => { this.isLoadingUsers = false });
      }
    });

    this.insertProtocolPage = localStorage.getItem('dnErp.insertProtocolPage') === "true";

  }

  ngAfterViewInit(): void {
   

    setTimeout(() => {
      if (this.deviceService.isMobile()) {
        this.txtSignerUser.nativeElement.focus();
      }
      else {
        this.txtSignerUser.nativeElement.focus();
      }

    }, 150);

    this.loadModels();

  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  userDisplayFn(item: UserResumeViewModel) {
    if (item) { return item.name; }
  }

  getUsers(event: any) {

    this.subjectUsers.next(event.target.value);

    // var inp = String.fromCharCode(event.keyCode);
    // if (/[a-zA-Z0-9-_ ]/.test(inp)) {
    //   let searchTerm = '';
    //   searchTerm += event.target.value;
    //   this.subjectUsers.next(searchTerm);
    // }
  }

  removeUser(item) {
    this.signers.splice(this.signers.indexOf(item), 1);
  }


  loadModels () {
    this.dataService.getSignatureModels(this.userService.userLoged.id, SignatureModelType.FieldsPosition).subscribe(resp=>{
      this.signatureModels = resp.data;
    });
  }
  
  changeFile(files) {
    console.log(files);

    if (files.length > 0) {
      this.file = files[0];
      this.fileName = files[0].name;
      this.fileSize = files[0].size; //// (files[0].size / 1000) + ' KB';

      console.log(' this.fileSize: ',  this.fileSize);

      this.formGroug.controls['fileName'].setValue(this.fileName);
    }
  }


  userSelected(event: MatAutocompleteSelectedEvent ) {
    
    //this.data.personId = event.option.value.id;

    console.log('user', event);
    this.signerUser = event.option.value;
    
    if(!this.signers.find(i=>i.userId == this.signerUser.id))
      this.addUserToSigners();
    
  }
  

  close() {
    this.onCancelClick.emit(null);
    this.dialog.closeAll();
  }


  docUploaded(event) {
    console.log('event: ', event);
  }



  submit() {

    const fileName: string = this.formGroug.controls['fileName'].value;

    this.formErrors = [];
    if (this.fileSize > 5000000) {
      Swal.fire('Oops...', 'O Arquivo Não deveria ser maior que 5MB!', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('fileName', fileName);
    formData.append('insertProtocolPage', this.insertProtocolPage.toString());
    formData.append('prepareDocumentMode', this.prepareMode.toString());

    if(this.selectedSignatureModel)
      formData.append('signatureJsonModelId', this.selectedSignatureModel.id);

    console.log('this.file', this.file);


    const uploadReq = new HttpRequest('POST', this.dataService.sharedInfo.serviceUrl + '/publicDocument/uploadFile', formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {

      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);

      } else if (event.type === HttpEventType.Response) {
        const retObj = JSON.parse(JSON.stringify(event.body));
        if (retObj.success === true) {
          // this.onSaved.emit({ id: this.idForEdit, data: ret.data });
          //this.onSaved.emit(retObj.data);
          localStorage.setItem('dnErp.insertProtocolPage', this.insertProtocolPage.toString());
          
          setTimeout(() => {
            this.addSigner(retObj.data);
          }, 1000);
         
        }
      }

    }, fail => {
      if (fail.error.errors) {
        this.formErrors = fail.error.errors;
      }
    });





  }


  addUserToSigners(){
    this.signers.push({
      userId: this.signerUser.id,
      userCPF: this.signerUser.cpf,
      userName: this.signerUser.name,
      userEmail: this.signerUser.email,
      userTitle: this.signerUser.signTitle
    });
  }

  removeUserToSigners(item){
    this.signers.splice(this.signers.indexOf(item), 1);
    this.signers = [... this.signers];
  }


  addSigner(documents: any[]){

    

    var data = {
      publicDocumentId: documents.map(i=>i.id),
      signers: this.signers,
      method: this.method,
    };

    this.dataService.insertSigner(data).subscribe(ret=>{

      this.onSaved.emit(ret.data);
      this.dialog.closeAll();

    }, fail => {
      if (fail.error.errors) {
        this.formErrors = fail.error.errors;
      }
    });

  }

  

}
