export const environment = {
  production: true,
  appName: 'dotneterp',

  localAPIurl: 'https://localhost:5005',
  loginUrl: 'https://auth.dotneterp.com.br/login?app=dotneterp',
  serviceUrl: 'https://apiv3.dotneterp.com.br',
  authUrl: 'https://core.dotneterp.com.br',
  signUrl: 'https://apiv2.dotneterp.com.br/signatureAPI',


  hmr: false,
  VERSION: "1.0.13",
};
