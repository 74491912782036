import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardSignaturePageRoutingModule } from './dashboard-routing.module';
import { DashboardSignaturePage } from './dashboard.page';

import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared-modules';
import { SignatureHomePageComponent } from './home-page/home-page.component';
import { SignatureSidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';

import { SignatureComponent } from './comp/signature-page/signature-page.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SignatureHeaderComponent } from './header/header.component';

import { TimelineModule } from "angular2-timeline";
import { DocumentService } from '../dashboard-sign/views/document/document.service';
import { NgxCurrencyModule } from 'ngx-currency';
import { customCurrencyMaskConfig } from '../services/configs/customCurrencyMaskConfig';
import { ViewDocumentComponent } from './views/document/view-document/view-document.component';
import { AddEditDocumentComponent } from './views/document/add-edit-document/add-edit-document.component';
import { DetailsDocumentComponent } from './views/document/details-document/details-document.component';
import { PrepareDocumentComponent } from './views/document/prepare-document/prepare-document.component';

import { SignerAddEditComponent } from './views/document/signer-add-edit/signer-add-edit.component';
import { SignFileComponent } from './views/document/sign-file/sign-file.component';
import { SignSendDocComponent } from './views/document/sign-send-doc/sign-send-doc.component';
import { PublicSignComponent } from './views/document/public-sign/public-sign.component';
import { SignFileEletronicComponent } from './views/document/sign-file-eletronic/sign-file-eletronic.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ResizableDraggableComponent } from '../comp/resizable-draggable/resizable-draggable.component';
import { SignRectangleComponent } from './comp/sign-rectangle/sign-rectangle.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { DndModule } from 'ngx-drag-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SignFieldModelsComponent } from './views/document/signature-field-model/signature-field-model.component'


const maskConfig: Partial<IConfig> = {
  validation: false,
};


@NgModule({
  imports: [
    CommonModule,
    // FormsModule,
    // ReactiveFormsModule,
    DashboardSignaturePageRoutingModule,
    SharedModule,
    MaterialModule,
    TimelineModule,
    SignaturePadModule,
    AngularDraggableModule,

    DndModule ,
    ImageCropperModule,

    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(maskConfig),
  ],
  declarations: [
    DashboardSignaturePage, 
    SignatureHomePageComponent,
    SignatureSidenavMenuComponent,

    SignatureComponent,
    SignatureHeaderComponent,
    ViewDocumentComponent,
    AddEditDocumentComponent,
    DetailsDocumentComponent,
    PrepareDocumentComponent,
    SignerAddEditComponent,
    SignFileComponent,
    SignFileEletronicComponent,
    SignSendDocComponent,
    PublicSignComponent,

    ResizableDraggableComponent,

    SignRectangleComponent,
    SignFieldModelsComponent

  ],

  entryComponents: [
    SignSendDocComponent, SignerAddEditComponent, SignFileComponent, AddEditDocumentComponent, SignFileEletronicComponent, SignFieldModelsComponent
  ],

  providers:[
    DocumentService
  ]
})
export class DashboardSignaturePageModule {}
