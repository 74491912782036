import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { AuthenticateUserModel } from '../models/user-model';
import { UnitiesAndConfigsModel, UnityModel } from '../models/plan-model';
import { Router } from '@angular/router';
import * as moment from 'moment';


@Injectable()
export class UserService {
  public userData: any;

  public avatarUrl = 'assets/images/avatar-blank.jpg';
  // userService?.userLoged.avatarUrl | 'assets/images/avatar-4.jpg'

  public tokenData;
  public userLoged: AuthenticateUserModel;


  public planInfo: UnitiesAndConfigsModel;
  public selectedUnit: UnityModel;

  public onUserChange: EventEmitter<AuthenticateUserModel> = new EventEmitter();
  public onUnityChange: EventEmitter<UnityModel> = new EventEmitter();

  public onApiCalled: EventEmitter<any> = new EventEmitter();

  public publicTokenData: string;


  constructor(private router: Router) {

    const data = localStorage.getItem('dnErp.user');

    if (data) {
      this.userData = data;
    }

  }



  authenticate(): boolean {
    this.tokenData = localStorage.getItem('dnErp.token');
    const data = localStorage.getItem('dnErp.user');
    if (data != null) {
      this.userData = data;

      return true;
    }

    return false;
  }


  loadToken(): string {
    this.tokenData = localStorage.getItem('dnErp.token');
    if (this.tokenData) {
      return this.tokenData;
    }
    return '';
  }


  setPlanInfo(planInfo: any) {
    localStorage.setItem('dnErp.planInfo', JSON.stringify(planInfo));
    this.planInfo = planInfo as UnitiesAndConfigsModel;
  }

  setUnity(unity: UnityModel) {
    localStorage.setItem('dnErp.selectedUnit', JSON.stringify(unity));
    this.selectedUnit = unity;
    this.onUnityChange.emit(unity);
  }

  loadUser(): AuthenticateUserModel {

    const planData = localStorage.getItem('dnErp.planInfo');
    if (planData != null) {
      this.planInfo = JSON.parse(planData) as UnitiesAndConfigsModel;
    }

    const selectedUnit = localStorage.getItem('dnErp.selectedUnit');
    if (selectedUnit != null) {
      this.selectedUnit = JSON.parse(selectedUnit) as UnityModel;
      this.onUnityChange.emit(this.selectedUnit);
    }


    this.userLoged = null;

    this.tokenData = localStorage.getItem('dnErp.token');
    const data = localStorage.getItem('dnErp.user');
    if (data != null) {
      this.userData = data;
      this.userLoged = JSON.parse(data) as AuthenticateUserModel;

      this.onUserChange.emit(this.userLoged);

      if (this.userLoged.avatarUrl !== '') {
        this.avatarUrl = this.userLoged.avatarUrl;
      }


      return this.userLoged;
    }
  }



  saveToken(token: string, refreshToken: string, expireToken :number) {
    var dateExpire = moment().add(expireToken, 'seconds').format('YYYY-MM-DDTHH:mm:ss');
    var refreshTokenIn = moment().add(expireToken / 3, 'seconds').format('YYYY-MM-DDTHH:mm:ss');

    this.tokenData = token;
    localStorage.setItem('dnErp.token', token);
    localStorage.setItem('dnErp.expireToken', dateExpire);
    localStorage.setItem('dnErp.refreshTokenIn', refreshTokenIn);
    localStorage.setItem('dnErp.refreshToken', refreshToken);
  }

  saveUser(user: any) {
    const data = JSON.stringify(user);
    localStorage.setItem('dnErp.user', data);
    this.userData = data;
  }






  logout() {
    localStorage.removeItem('dnErp.token');
    localStorage.removeItem('dnErp.user');
    localStorage.removeItem('dnErp.planInfo');
    localStorage.removeItem('dnErp.selectedUnit');

    localStorage.removeItem("dnErp.refreshToken");
    localStorage.removeItem("dnErp.refreshTokenIn");


    setTimeout(() => {
      this.tokenData = null;
      this.userData = {};
      this.userLoged = null;
      this.onUserChange.emit(null);
      this.router.navigate(['/auth/logout']);
    }, 100);
  }







}
