import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user-service';
import { LoginDataService } from './login-service';
import { DOCUMENT } from '@angular/common';



@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService, private loginDataService: LoginDataService,  @Inject(DOCUMENT) private document: Document) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let lastReloadedInfo: number = null;
    const lastData = localStorage.getItem('dnErp.lastReloadedInfo');
    if (lastData != null) {
      lastReloadedInfo = parseInt(lastData, 10);
    }

    if (localStorage.getItem('dnErp.user')) {
      // logged in so return true


      if (lastReloadedInfo != null) {
        const msec = Date.now() - lastReloadedInfo;
        const minutes = Math.floor(msec / 60000);
        const hrs = Math.floor(minutes / 60);
        const days = Math.floor(hrs / 24);
        // const yrs = Math.floor(days / 365);

        if (minutes > 1) {

          this.loginDataService.getUserUnities().subscribe(response => {
            if (response.data) {
              this.userService.setPlanInfo(response.data);
              localStorage.setItem('dnErp.lastReloadedInfo', Date.now().toString());
            }
          });
        }
      }


      if (this.userService.planInfo == null) {
        //try { await this.getUserUnities(); } catch (error) { }
      }


      if (this.userService.planInfo == null) {
        console.log('Autenticar Novamente Para Carregar Planos...');
        //this.router.navigate(['auth/login'], { queryParams: { reqPage: state.url } });
        this.document.location.href = `${this.loginDataService.sharedInfo.loginUrl}&reqPage=${state.url}`;
        return false;
      }


      if ((!this.userService.planInfo.plan) || ((this.userService.planInfo.unities || []).length <= 0)) {
        await this.getUserUnities();

        // Se mesmo após carregar o plano a lista de unidades estar vazia:
        if((this.userService.planInfo.unities || []).length <= 0){
          //Navegar para a tela de selecionar plano:
          this.router.navigate(['auth/setunit']);
        }
      }


      if (!this.userService.planInfo.plan) {
        console.log('Falta Configurar um Plano...');
        this.router.navigate(['auth/setPlan']);
        return false;
      }


      if (this.userService.planInfo.hasDataBase === false) {
        console.log('Inicializando o Banco de Dados...');

        var resp = await this.loginDataService.checkDb().then();
        if(resp.success == true){
          localStorage.setItem('dnErp.planInfo', JSON.stringify(resp.data))
          return true;
        }
        return false;
      }


      // if ((this.userService.planInfo.unities || []).length <= 0) {
      //   console.log('Falta Configurar as Unidades...');
      //   this.router.navigate(['maintenance/setPlan']);
      //   return false;
      // }



      return true;
    }

    // not logged in so redirect to login page with the return url
    console.log('Falta Autenticar', route );
    this.document.location.href = `${this.loginDataService.sharedInfo.loginUrl}&reqPage=${state.url}`;
    return false;
  }

  async getUserUnities() {
    const response = await this.loginDataService.getUserUnities().toPromise();

    if (response.data) {
      this.userService.setPlanInfo(response.data);
      localStorage.setItem('dnErp.lastReloadedInfo', Date.now().toString());
    }

    return response;
  }


}
