import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SharedInfo } from './shared-info';
import { UserService } from './user-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseCommand } from '../models/response-command';
import { environment } from 'src/environments/environment';


@Injectable()
export class LoginDataService {
  public headers: Headers;

  constructor(
    public sharedInfo: SharedInfo,
    public userService: UserService,
    private http: HttpClient) {

    this.headers = new Headers({ 'Content-Type': 'application/json' });
    this.headers.append('Access-Control-Allow-Origin', '*');
  }



  getPlans(): Observable<any> {
    return this.http
    .get<any>(this.sharedInfo.authUrl + '/plan/getPlans?appName=' + environment.appName);
  }

  getUserClaims(userId): Observable<any> {
    return this.http
      .get<any>(this.sharedInfo.authUrl + '/account/getUserClaims?UserId=' + userId);
  }

  getTokenByCode(code): Observable<any> {
    return this.http
      .get<any>(this.sharedInfo.authUrl + '/account/getTokenByCode?code=' + code);
  }

  getUserData(userId): Observable<any> {
    return this.http
      .get<any>(this.sharedInfo.authUrl + '/account/getUserData?UserId=' + userId);
  }

  updateMainUserData(data): Observable<any> {
    return this.http
      .put<any>(this.sharedInfo.authUrl + '/account/updateMainUserData', data);
  }


  getUserUnities(): Observable<any> {
    return this.http
    .get<any>(this.sharedInfo.authUrl + '/plan/unities?appName=' + environment.appName);
  }

  getClients(strFind, activated): Observable<any> {
    var filterData = {
      strFind: strFind || '',
      page: 1,
      take: 20,
      activated : activated
    };
    return this.http
      .put<any>(this.sharedInfo.authUrl + '/subscription/erpclients', filterData);
  }

  changeErp(data): Observable<any> {
    console.log('data', data);
    return this.http
      .put<any>(this.sharedInfo.authUrl + '/user/changeErp', data);
  }

  getUsers(strFind): Observable<any> {
    return this.http
      .get<any>(this.sharedInfo.authUrl + '/adm/getUsers?strFind=' + strFind);
  }

  checkDb(): Promise<any> {
    return new Promise((resolve, reject) => {

      this.http.get<any>(this.sharedInfo.serviceUrl + '/util/checkDb').subscribe(resp => {
        resolve(resp);
      }, err => {
        reject();
      })
    });
  }


  activatePlan(planId: string): Observable<any> {
    return this.http
      .post<ResponseCommand>(this.sharedInfo.authUrl + '/plan/activate?planId=' + planId, {});
  }



  authenticate(data: any) {
    return this.http.post<ResponseCommand>(this.sharedInfo.authUrl + '/account/login',
      { email: data.email, password: data.password, rememberMe: data.rememberMe, refreshToken: data.refreshToken });
  }


  createUser(cmd: any) {

    return this.http
      .post<ResponseCommand>(this.sharedInfo.authUrl + '/account/register', cmd);
  }

  updateUser(cmd) {
    return this.http
      .post<ResponseCommand>(this.sharedInfo.authUrl + '/user/update', cmd);
  }

  sendConfirmation(email) {
    return this.http
      .post<ResponseCommand>(this.sharedInfo.authUrl + '/account/sendConfirmation', { email: email });
  }

  sendResetPassword(cmd) {
    return this.http
      .post<ResponseCommand>(this.sharedInfo.authUrl + '/account/sendResetPassword', cmd);
  }

  resetPassword(cmd) {
    return this.http
      .post<ResponseCommand>(this.sharedInfo.authUrl + '/account/resetPassword', cmd);
  }

  confirmEmail(email, token) {
    return this.http
      .post<ResponseCommand>(this.sharedInfo.authUrl + '/account/confirmEmail', { email: email, tokenConfirmEmail: token });
  }

  userSetPhoto(photo64) {
    return this.http
      .post<ResponseCommand>(this.sharedInfo.authUrl + '/user/postImage',
        { user_id: this.userService.userLoged.id, image: photo64 });

  }

  getUserModel(id): Observable<any> {
    return this.http
      .get<any>(this.sharedInfo.authUrl + '/user/get/' + id);
  }

  createUnity(cmd: any) {
    return this.http
      .post<ResponseCommand>(this.sharedInfo.authUrl + '/account/createunity', cmd);
  }

}
