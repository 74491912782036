


import { Component, ViewChild, Output, EventEmitter, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { DocumentService } from 'src/app/dashboard-sign/views/document/document.service';
import { DomSanitizer, } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user-service';
import { SignaturePad } from 'angular2-signaturepad';
import Swal from 'sweetalert2';
import { Signature, SignatureMethod } from 'src/app/dashboard-sign/models/portalSign-models';
import html2canvas from 'html2canvas';
import { threadId } from 'worker_threads';
import { DocumentSignModel, FieldInfo, SignatureModelViewModel } from '../document.models';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SignatureModelType } from 'src/app/dashboard-sign/enums/sig-enums';
import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist';

@Component({
  selector: 'app-signature-field-model',
  templateUrl: './signature-field-model.component.html',
  styleUrls: [
    'signature-field-model.component.scss'
  ],
})
export class SignFieldModelsComponent implements OnInit, AfterViewInit, OnDestroy {

  isLoading = false;

  @Output()
  public onCancelClick = new EventEmitter<any>();

  @Output()
  public onSaved = new EventEmitter<any>();

  @Output()
  public onPageChange = new EventEmitter<number>();


  @Input()
  jsonFieldsToSave: string;

  @Input()
  imageDataToSave: string;

  @Input()
  pdfPageNumberToSave: number;

  @Input()
  pdfPageCount: number;

  @Input()
  selectItemMode: boolean;


  currentPage:any;
  imagesUrl: string = '';
  titleToAddmodel: string;
    
  signatureModels: SignatureModelViewModel[] = [];
  selectedSignatureModel: SignatureModelViewModel;



  @ViewChild("selectSigModelDialog") selectSigModelDialog: any;
  @ViewChild("addSigModelDialog") addSigModelDialog: any;


  constructor(
  public dataService: DocumentService,
  public sanitizer: DomSanitizer,
  public userService: UserService,) {

      this.imagesUrl = this.dataService.sharedInfo.serviceUrl + '/images/signatures/' + this.userService.userLoged.id;

  }

  ngOnDestroy(): void { }

  ngOnInit(): void { }

  ngAfterViewInit() {
   
    if(this.jsonFieldsToSave) {
      this.goAddModel();
      this.onPageChange.emit(1);
    }
    else
      this.goSelectModel();

  }

  goAddModel() {
    this.currentPage = this.addSigModelDialog;
  }

  goSelectModel() {
    this.currentPage = this.selectSigModelDialog;
    this.loadModels();
  }

  pageChaged(){
    this.onPageChange.emit(this.pdfPageNumberToSave);
  }

  onSelectedSignatureModel(){

  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  close() {
    this.onCancelClick.emit(null);
  }

  loadModels (id?) {
    this.dataService.getSignatureModels(this.userService.userLoged.id, SignatureModelType.FieldsPosition).subscribe(resp=>{
      this.signatureModels = resp.data;
      if(id){
        this.selectedSignatureModel = this.signatureModels.find(i=>i.id == id);
      }
    });
  }

  selectItem() {
    this.onSaved.emit(this.selectedSignatureModel);
  }

  saveModel() {
    
    var model: SignatureModelViewModel = new SignatureModelViewModel();
      model.id =  uuidv4();
      model.imgData = this.imageDataToSave;
      model.title = this.titleToAddmodel;
      model.userId = this.userService.userLoged.id;
      model.jsonField = this.jsonFieldsToSave;
      model.type = SignatureModelType.FieldsPosition;

      this.dataService.addSignatureModels(model).subscribe(resp=>{
        this.loadModels(model.id);
        this.goSelectModel();
        this.titleToAddmodel = '';
        this.jsonFieldsToSave = '';
      });

  }

  deleteModel(){
    if(this.selectedSignatureModel){
      this.dataService.deleteSignatureModel(this.selectedSignatureModel.id).subscribe(resp=>{
        this.selectedSignatureModel = null;
        this.loadModels();
      });
    }
  }

  

  tryError(error){
    const ret = error.error;

      if (ret.errors) {
        console.log(ret.errors);

        let errorMessage = '';
        ret.errors.forEach(element => {
          if (element.key === '') {
            errorMessage += (element.value + '</BR>');
          }
        });

        if (errorMessage !== '') {
          Swal.fire('Oops...', errorMessage, 'error');
        }

      } else {
        Swal.fire('Oops...', 'Não foi possível enviar os dados', 'error');
      }

  }

}
