import { DatatableComponent } from '@swimlane/ngx-datatable';
import { CatalogDataService } from '../services/catalog-service';

import { ActivatedRoute } from '@angular/router';
import { ViewChild, HostListener, EventEmitter, ElementRef, Injectable, Component, Inject, Directive } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { MatPaginator } from '@angular/material/paginator';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { DeviceDetectorService } from 'ngx-device-detector';


export interface ShDataService {
  getData(filterData): Observable<any>;
  findData(strFind): Observable<any>;
  getItemDetails(id: string): Observable<any>;
  delete(id: string): Observable<any>;
  getColums(): any[];
  routerTitle: string;
  tableName: string;
  filterData: any;
  options: any;
}


@ Directive ({
  selector: '[pageSharedFns]',
} )
export class PageSharedFns {

  public appService: ShDataService;

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public deviceService: DeviceDetectorService) {

    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

    console.log('isMobile:', this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log('isTablet:', this.isTablet);  // returns if the device us a tablet (iPad etc)
    console.log('isDesktopDevice:', this.isDesktopDevice); // returns if the app is running on a Desktop browser.

    this.onKeyUpEvent = new EventEmitter<any>();
    this.onKeyUpEvent.subscribe((key) => {

      if (key === 'F2' && this.isModalOpened === false) {
        const modalRef = this.openModal(this.filterModal);
      }

    });




  }


  @ViewChild('strFindControl') strFindControl;
  @ViewChild('filterModal') filterModal;

  @ViewChild('tbPaginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  columns: any[] = [];
  displayedColumns: string[] = [];

  onKeyUpEvent = new EventEmitter;
  deviceInfo = null;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;

  public tableName = '';

  isLoadingResults = true;

  data: any[] = [];
  temp = [];
  cache: any = {};

  strFind = '';
  resultsLength = 0;
  isRateLimitReached: boolean = false;
  pageNumber = 0;

  selected = [];
  selectedItem: any;

  readonly headerHeight = 50;
  readonly rowHeight = 50;

  timeout = null;
  isModalOpened = false;



  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit(): void {


    this.route.params.subscribe(params => {
      this.tableName = params['tableName'];

      setTimeout(() => {

        if (this.sort) {
          this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    
    
          merge(this.sort.sortChange, this.paginator.page)
            .pipe().subscribe(data => {
              this.fetch(null);
            });
        }

        
        this.fetch(null);
      }, 50);

      if (this.isDesktopDevice && this.strFindControl) {
        this.strFindControl.nativeElement.focus();
      }
    });

    setTimeout(() => {
      if (this.isDesktopDevice && this.strFindControl) {
        this.strFindControl.nativeElement.focus();
      }

    }, 100);
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    // console.log('Key:', event.key);
    // console.log('this.onKeyUpEvent:', this.onKeyUpEvent);
    this.onKeyUpEvent.emit(event.key);
  }


  selectItem(item) {
    this.selectedItem = item;
    this.selected = [item];
  }


  fetch(itemSaved?: any) {

    if (itemSaved != null) {

      console.log('itemSaved: ', itemSaved);

      if (itemSaved.id != null) {
        const itemInRows = this.data.find(i => i.id === itemSaved.id);
        if (itemInRows) {
          const indOfItem = this.data.indexOf(itemInRows);
          
          if(itemSaved.data.mainData)
            this.data.splice(indOfItem, 1, itemSaved.data.mainData);
          else
            this.data.splice(indOfItem, 1, itemSaved.data);

          this.data = this.data.concat([]);
          this.resultsLength = this.resultsLength;
          return;
        }
      } else {

        if(itemSaved.data.mainData)
          this.data = [].concat([itemSaved.data.mainData], this.data);
        else
          this.data = [].concat([itemSaved.data], this.data);

        this.resultsLength = this.resultsLength + 1;
        return;
      }

    }

    this.setPageTake();
    this.isLoadingResults = true;
    this.appService.getData(this.appService.filterData).subscribe(response => {

      this.isLoadingResults = false;

      if (response.success === false) {
        // this.shFunctions.showAlert('Oops.', data.errorMessage);
        console.log(response);

      } else {

        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = response.data.rowsInDB;

        this.data = response.data.itens;

        console.log('data', this.data);
      }

    }, error => {
      this.isLoadingResults = false;
      this.isLoadingResults = false;
      this.isRateLimitReached = true;
      this.data = [];
    });

  }

  setPageTake() {

    if(this.paginator) {
      this.appService.filterData.page = (this.paginator.pageIndex + 1) || 1;
      this.appService.filterData.take = this.paginator.pageSize;

    } else {
      this.appService.filterData.page = 1;
      this.appService.filterData.take = 50;
    }

    if(this.sort) {
      this.appService.filterData.sortColName = this.sort.active;
      this.appService.filterData.sortDirection = this.sort.direction;
    }

  }

  updateFilter(event) {

    // setTimeout(() => {  this.fetch(); }, 1500);
    // this.fetch();
    // this.table.offset = 0;
    clearTimeout(this.timeout);
    // setTimeout(myFunc,5000);
    this.timeout = setTimeout(() => {
      //this.strFind = event.target.value.toLowerCase();
      this.appService.filterData.strFind = this.strFind;
      this.fetch(null);
    }, 1500);
  }


  onSelect({ selected }) {
    this.selectedItem = selected[0];
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  openModal<T>(content: any, modalSize = 'md', data = null): MatDialogRef<T> {
    return this.dialog.open(content, {
      panelClass: 'mat-dialog-edit-card',
      disableClose: false,
      data: data
    });
  }


  setFilter(evFilter: any) {
    console.log(evFilter);
    this.strFind = evFilter.strFind;
    this.appService.filterData = evFilter;
    this.fetch(null);
  }

}
