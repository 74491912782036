


import { Component, ViewChild, Output, EventEmitter, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { DocumentService } from 'src/app/dashboard-sign/views/document/document.service';
import { DomSanitizer, } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user-service';
import { SignaturePad } from 'angular2-signaturepad';
import Swal from 'sweetalert2';
import { Signature, SignatureMethod } from 'src/app/dashboard-sign/models/portalSign-models';
import html2canvas from 'html2canvas';
import { threadId } from 'worker_threads';
import { DocumentSignModel, FieldInfo, SignatureModelViewModel } from '../document.models';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SignatureModelType } from 'src/app/dashboard-sign/enums/sig-enums';

@Component({
  selector: 'app-sign-file-eletronic',
  templateUrl: './sign-file-eletronic.component.html',
  styleUrls: [
    'sign-file-eletronic.component.scss'
  ],
})
export class SignFileEletronicComponent implements OnInit, AfterViewInit, OnDestroy {


  @Output()
  public onCancelClick = new EventEmitter<any>();

  @Output()
  public onSaved = new EventEmitter<any>();

  @Input()
  documentList: DocumentSignModel[] = [];

  @Input()
  userSigInfo: Signature;

  isLoading = false;

  imageData: string;

  documentsSigned: DocumentSignModel[] = [];

  pubToken: any;

  fontNames: string[] = ['Kunstler Script', 'Vladimir Script', 'Freestyle Script', 'Brush Script MT, Brush Script Std, cursive'];
  imgDigitSignatureID: string;

  fieldToAddmodel: FieldInfo;
  titleToAddmodel: string = '';

  signatureModels: SignatureModelViewModel[] = [];
  selectedSignatureModel: SignatureModelViewModel;

  imagesUrl: string = '';
  currentPage: any;

  requiredLocalPlugin: boolean = false;
  cropperBackgroundColor: string = 'gray';

  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  @ViewChild("addSigModelDialog") addSigModelDialog: any;
  @ViewChild("selectSigModelDialog") selectSigModelDialog: any;
  @ViewChild("signLocalPluginDialog") signLocalPluginDialog: any;

  @ViewChild("txtTitle") txtTitle: any;


  constructor(
    public dataService: DocumentService,
    public sanitizer: DomSanitizer,
    public userService: UserService,) {

      
  }

  ngOnDestroy(): void {
    
  }

  ngOnInit(): void {



  }

  goAddModel() {
    this.currentPage = this.addSigModelDialog;
    this.fieldToAddmodel = this.documentList[0].fields[0];
    setTimeout(() => {
      this.signaturePad.clear();
      if(this.txtTitle)
        this.txtTitle.nativeElement.focus();
    }, 50);
  }

  goSelectModel() {
    this.currentPage = this.selectSigModelDialog;
  }

  async goLocalPluginSignature() {
    this.selectedSignatureModel.urlImage = `${this.imagesUrl}/${ this.selectedSignatureModel.id + '.' + this.selectedSignatureModel.imgExt}`;
    this.currentPage = this.signLocalPluginDialog;
  }

  setImageData(data){
    this.imageData = data;
  }

  onSelectedSignatureModel(){

  }
  

  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 0.3,
    'maxWidth': 2,
    'canvasWidth':  window.innerWidth < 750 ? window.innerWidth - 12 : 750,
    'canvasHeight': window.innerWidth < 750 ? 100 : 160,
    'penColor': 'rgb(66, 133, 244)',
    'backgroundColor': 'transparent'
  };

  ngAfterViewInit() {

    this.imagesUrl = this.dataService.sharedInfo.serviceUrl + '/images/signatures/' + this.userSigInfo.userId;
    
    
    if (this.userSigInfo.method == SignatureMethod.DigitalCertificate) {

      if (this.userService.userLoged 
      && !this.userService.userLoged.certificateFileName) {
        this.requiredLocalPlugin = true;
      } else if(!this.userService.userLoged ) {
        this.requiredLocalPlugin = true;
      }

    }

    if(this.userService.userLoged)//Se está logado solicita modelos
      this.currentPage = this.selectSigModelDialog;    
    else
      this.currentPage = this.addSigModelDialog;


    this.loadModels();
  }

  drawComplete() {
    this.imageData = this.signaturePad.toDataURL("image/png");
    //console.log(this.imageData);
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  close() {
    this.onCancelClick.emit(null);
  }

  loadModels (id?) {
    if(this.userSigInfo.userId) {
      this.dataService.getSignatureModels(this.userSigInfo.userId, SignatureModelType.SignatureImage).subscribe(resp=>{
        this.signatureModels = resp.data;
        if(id){
          this.selectedSignatureModel = this.signatureModels.find(i=>i.id == id);
        }
      });
    }
  }

  saveModel() {
    
    if(this.userService.userLoged) {
      var model: SignatureModelViewModel = new SignatureModelViewModel();
      model.id =  uuidv4();
      model.imgData = this.imageData;
      model.title = this.titleToAddmodel;
      model.userId = this.userSigInfo.userId;
      model.jsonField = JSON.stringify(this.fieldToAddmodel);
      model.type = SignatureModelType.SignatureImage;

      this.dataService.addSignatureModels(model).subscribe(resp=>{
        this.loadModels(model.id);
        this.goSelectModel();
        this.titleToAddmodel = '';
        this.fieldToAddmodel = null;
      });
    }
    else {
      //Solicitar plugin local
      this.goLocalPluginSignature();
    }
  }

  deleteModel(){
    if(this.selectedSignatureModel){
      this.dataService.deleteSignatureModel(this.selectedSignatureModel.id).subscribe(resp=>{
        this.selectedSignatureModel = null;
        this.loadModels();
      });
    }
  }

  imageChangedEvent: any = '';
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageData = event.base64;
  }

  exportImg() {

    var node = document.getElementById(this.imgDigitSignatureID);

    //let element = document.querySelector("#rec0001");
    html2canvas(node, { scale: 1.5 }).then(canvas => {
      // Convert the canvas to blob
      //document.body.appendChild(canvas);

      var imageData  = canvas.toDataURL('image/png', 1.0);
      this.setImage(imageData);

     // debugger;

      // canvas.toBlob(blob => {
      //   var unsafeImageUrl = URL.createObjectURL(blob);
      //   var imageData = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);

      //   debugger;

      //   this.setImage(blob);

      // }, 'image/png');
    });
  }

  setImage(img){
    this.imageData = img;
    //console.log('img', img);
  }

  sign(){
    
    if (this.userSigInfo.method == SignatureMethod.DigitalCertificate) {

      if(this.userService.userLoged && this.userService.userLoged.certificateFileName && this.userSigInfo.userId == this.userService.userLoged.id)
      {
        this.signDocumentOnLineA1();
      } else {
        this.goLocalPluginSignature();
      }
    
    }
    else {
      this.signDocumentOnLine();
    }
  }

  signDocumentOnLine() {

    if(!this.documentList || ( this.documentList && this.documentList.length <= 0)) {
      this.onSaved.emit(this.imageData);
      return;
    }

    this.documentList.forEach(document => {

      this.dataService.signDocumentOnLine(
        document.userId,
        document.publicDocumentId, 
        document.signatureId, 
        this.selectedSignatureModel.id, 
        this.imageData,
        SignatureMethod.Eletronic).subscribe(ret => {
        if (ret.success === true) {
          this.onSaved.emit();
          this.documentsSigned.push(ret.data);
        }
      }, error => {
  
        this.tryError(error);
      });
      
    });

  }


  onLocalPluginSigned(e) {
    this.onSaved.emit(e);
  }

  signDocumentOnLineA1() {

    var command = {
      certSerial: '',
      urlAPI: this.dataService.sharedInfo.serviceUrl,
      token: this.pubToken ? this.pubToken : this.userService.tokenData,
      imgData: this.imageData,
      documents: this.documentList
    };

    if(this.userSigInfo.userId && this.selectedSignatureModel){
      command["signatureModelId"] = this.selectedSignatureModel.id;
      command["signatureModelFileName"] = `${this.userSigInfo.userId}\\${ this.selectedSignatureModel.id + '.' + this.selectedSignatureModel.imgExt}`
    }


    this.dataService.signDocumentOnLineA1(command).subscribe(ret => {
      if (ret.success === true) {
        this.onSaved.emit();
        this.documentsSigned.push(ret.data);
      }
    }, error => {
      this.tryError(error);
    });
  }


  tryError(error){
    const ret = error.error;

      if (ret.errors) {
        console.log(ret.errors);

        let errorMessage = '';
        ret.errors.forEach(element => {
          if (element.key === '') {
            errorMessage += (element.value + '</BR>');
          }
        });

        if (errorMessage !== '') {
          Swal.fire('Oops...', errorMessage, 'error');
        }

      } else {
        Swal.fire('Oops...', 'Não foi possível enviar os dados', 'error');
      }

  }

}
