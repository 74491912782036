import { Injectable, NgModule, Optional, Inject } from '@angular/core';
import swal from 'sweetalert2';

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserService } from './user-service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService, NgxUiLoaderHttpConfig, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { finalize, map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import * as moment from 'moment';
import { LoginDataService } from './login-service';
import { SharedInfo } from './shared-info';
import { DOCUMENT } from '@angular/common';
// import { NgxUiLoaderHttpInterceptor } from '../../../node_modules/ngx-ui-loader/lib/http/ngx-ui-loader-http.interceptor';



@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(
    public userService: UserService,
    public dataService: LoginDataService,
    private router: Router, 
    private ngxLoader: NgxUiLoaderService) {

    console.log('interceptor init...');
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {


    console.log('start loader');

    if (req.url.indexOf('getResume') <= 0) {
      try {
        this.ngxLoader.start();

      } catch (error) { }

    }



    return next.handle(this.addToken(req,  this.userService.publicTokenData || this.userService.tokenData)).pipe(finalize(() => {


      console.log('stopAll loaders');
      console.log(req.url);

      if(req.url.indexOf('account/login') < 0){
        this.userService.onApiCalled.emit(null);
      }

      try {
        this.ngxLoader.stop();

      } catch (error) { }

    }));




  }


  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {

    // this.headers = new Headers({ 'Content-Type': 'application/json' });
    // this.headers.append('Access-Control-Allow-Origin', '*');

    if(req.url.indexOf('viacep.com.br') < 0){
      return req.clone({ setHeaders: { Authorization: 'Bearer ' + token, 'Access-Control-Allow-Origin': '*' } });
    }
    else{
      return req;
    }
    
  }
  


}




@Injectable()
export class HttpsRequestInterceptorErrors implements HttpInterceptor {

  constructor(
    private sharedInfo: SharedInfo,
    public userService: UserService,
    public dataService: LoginDataService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document) {


  }

  // interceptxx(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   request = request.clone({
  //     setHeaders: {
  //       Authorization: `Basic authentication-key`
  //     }
  //   });
  //   return next.handle(request);
  // }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // const token: string = localStorage.getItem('token');
    // if (token) {
    //   request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    // }
   

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event);
          // this.errorDialogService.openDialog(event);
        }
        return event;
      }),
      catchError((err: HttpErrorResponse) => {

        if(err.url.indexOf('account/login') >= 0){
          return throwError(err);
        }

        let errorMessage = '';

        console.log('response...', err);

        if (err.status === 400 || err.status === 422) {

          const retError = err.error;

          if (retError.errors) {
            console.log('intercept ret.errors:', retError.errors);

            retError.errors.forEach(element => {
              errorMessage += ((element.value || element.Value || 'Não foi possível acessar o servidor..') + '</BR>');
            });



          } else {
            errorMessage += 'Não foi possível acessar o servidor..', 'error';
          }


          if (errorMessage !== '') {
            //swal.fire('Oops...', errorMessage, 'error');
            this.sharedInfo.onNotifierError.emit({type: "warn", msg: errorMessage})
          }


          return throwError(err);
        }


        if (err.status === 401) {

          //Falha da autorização
          //this.router.navigate(['auth/login'], { queryParams: { reqPage: this.router.url } });
          this.document.location.href = `${this.sharedInfo.loginUrl}&reqPage=${this.router.url}`;

        }

        else if (err.status === 403) {
          this.sharedInfo.onNotifierError.emit({type: "error", msg: 'Falta Permissões para executar essa operação!'})
          return throwError(err);
        }

        else if (err.status === 500) {

          if (request.url.indexOf('getCertificates') > -1) {
            return throwError(err);
          }


          const retError = err.error;

          // if (retError.databaseError === true && this.userService.) {
          //   // Provávelmente o Banco de Dados Não Foi Criado, ou está com problemas de acesso
          //   // Ir para página de verificação do Banco
          //   this.router.navigate(['maintenance/check-db']);
          //   return throwError(err);
          // }

          if (retError.errors) {
            console.log('intercept ret.errors:', retError.errors);

            retError.errors.forEach(element => {
              errorMessage += ((element.value || element.Value || 'Não foi possível acessar o servidor..') + '</BR>');
            });

            if (errorMessage !== '') {
              swal.fire('Oops...', errorMessage, 'error');
            }

          } else {
            swal.fire('Oops...', 'Não foi possível acessar o servidor..', 'error');
          }



        } else {
          swal.fire('Oops...', 'Não foi possível acessar o servidor!<BR>Verifique sua conexão com a internet', 'error');
        }

        return throwError(err);
      }));
  }

}










@NgModule({
  providers: [


    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptorErrors,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },



  ],
})


export class Interceptor { }
