


import { Component, Output, EventEmitter, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';

import { DocumentService } from 'src/app/dashboard-sign/views/document/document.service';
import { DomainNotification } from '../../../../models/domainNotification';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { DomSanitizer, } from '@angular/platform-browser';


import { UserService } from 'src/app/services/user-service';
import { SocketService } from 'src/app/services/websocket-service';
import { LocalSignCommand, SignCommandType } from 'src/app/models/localsign-command';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-sign-file',
  templateUrl: './sign-file.component.html',
  styleUrls: [
    'sign-file.component.scss'
  ],
})
export class SignFileComponent implements OnInit, OnDestroy {


  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onCancelClick = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  public onSaved = new EventEmitter<any>();

  @Input()
  documentList: any[] = [];

  @Input()
  pubToken: any;

  @Input()
  imageData: any;

  @Input()
  signatureModelUrlImage: string;

  isLoading = false;
  progress: number;

  filesSigned: any[] = [];
  allFilesSigned = false;
  localCertificates: any[] = [];
  sugestionCertificate: any;

  respSubscribe: Subscription;

  formGroug: FormGroup;
  formErrors: DomainNotification[] = [];

  erroAccessPlugin = 'Não foi possível acessar o PlugIn local de assinaturas';
  showMsgDownloadPlugIn = false;

  localSrc: any;

  windowFeatures = `menubar=0,
  location=0,
  resizable=1,
  scrollbars=1,
  status=0,
  width=500,`;


  constructor(
    private http: HttpClient,
    public server: SocketService,
    public dataService: DocumentService,
    private formBuilder: FormBuilder,
    public sanitizer: DomSanitizer,
    public userService: UserService,
    private ngxLoader: NgxUiLoaderService,) {

    this.formGroug = this.formBuilder.group({
      file: [null],
      fileName: [''],
      userIdSign: [''],
    });

    this.respSubscribe = this.server.onResponseData.subscribe(pluginResponse => {
      console.log('pluginResponse', pluginResponse);

      //Add documents signed in list
      if (pluginResponse && pluginResponse.success == true) {


        switch (pluginResponse.commandType) {

          case SignCommandType.SignDocument:
            {
              pluginResponse.document = JSON.parse(pluginResponse.data);
              this.filesSigned.push(pluginResponse);

              if (this.filesSigned.length >= this.documentList.length) {
                this.allFilesSigned = true;
                this.ngxLoader.stopAll();
              }
              break;
            }

          case SignCommandType.UploadDocument:
            {
              pluginResponse.document = JSON.parse(pluginResponse.data);

              var file = this.filesSigned.find(i => i.document.publicDocumentId == pluginResponse.document.publicDocumentId);
              if (file) {
                file.sended = true;
                this.totalFileSigned += 1;
              }

              if (this.totalFileSigned >= this.filesSigned.length) {
                setTimeout(() => {
                  this.onSaved.emit(pluginResponse);
                }, 500);
              }

              
              break;
            }


          default:
            break;
        }




      }

      if (pluginResponse && ( pluginResponse.success == false || !pluginResponse.success )) {
        this.ngxLoader.stopAll();

        if(pluginResponse.data && pluginResponse.data.indexOf('"errors"')) {
          console.log('errors:', pluginResponse.data);

          var retError = JSON.parse(pluginResponse.data);
          var errorMessage = '';
          retError.errors.forEach(element => {
            errorMessage += ((element.value || element.Value) + '</BR>');
          });

          Swal.fire("O'ops", errorMessage, 'error');

        } else {
          if(pluginResponse.message){
            Swal.fire("O'ops", pluginResponse.message, 'error');
          }
        }

      }

    });


  }

  ngOnDestroy(): void {
    this.respSubscribe.unsubscribe();
  }

  ngOnInit(): void {
    // this.fileInput.nativeElement.click();


    // if (this.signalRService.getState() === HubConnectionState.Disconnected) {
    //   this.initHub();
    // }


    setTimeout(() => {

      var version = "1.0.6"

      this.server.sendCommand(
        new LocalSignCommand(SignCommandType.CheckStatus, { pluginVersion: version}), true).then(
          callBack => {

            console.log(callBack);

            if(callBack && callBack.version == version) {

              this.sugestionCertificate = null;

              this.server.sendCommand(
                new LocalSignCommand(SignCommandType.Certificate_Get, {}), true).then(
                  callBack => {
        
                    this.localCertificates = callBack;

                    this.localCertificates.forEach(element => {
                      this.documentList.forEach(document => {
                        if(element.name.indexOf(':'+ document.documentNumber) >= 0 ){
                          element.suggestion = true;
                          this.sugestionCertificate = element;
                        }
                      });
                    });

                    if(this.sugestionCertificate != null) {
                        setTimeout(() => {
                          this.signDocument(this.sugestionCertificate);
                        }, 400);
                    }
        
        
                  }, error => {
        
                    this.showMsgDownloadPlugIn = true;
        
                  });

            } else {
              Swal.fire('Versão do plugin Desatualizada', 'Solicite suporte para atualização, ou baixe novamente o instalador', 'error');
              this.showMsgDownloadPlugIn = true;
            }


          }, error => {

            Swal.fire('Falha ao acessar plugin', 'Solicite suporte, ou baixe novamente o instalador', 'error');
            this.showMsgDownloadPlugIn = true;



      });


      
    }, 1000);

  }

  command: any;

  async signDocument(cert) {

    var token = this.userService.tokenData;


    if (this.pubToken != null) {
      token = this.pubToken;
      console.log('signature from public token');
    }

    var serverDate = await this.dataService.getServerDate().then();

    if(!serverDate || !serverDate.data) {
      Swal.fire("O'ops", 'Falha ao verificar a data do servidor!', 'warning');
      return;
    }

    //SignDocumentCommand
    this.command = {
      certSerial: cert.thumbprint,
      urlAPI: this.dataService.sharedInfo.serviceUrl,
      token: token,
      imgData: this.imageData,
      signatureModelUrlImage: this.signatureModelUrlImage,
      date_OfSignature: serverDate.data.date,
      utcDate_OfSignature: serverDate.data.utcDate
    };

    if (this.documentList) {
      this.command.documents = this.documentList;
    }

    this.ngxLoader.start();

    this.server.sendCommand(
      new LocalSignCommand(SignCommandType.SignDocument, this.command), false).then(
        callBack => {

          if (callBack) {
            if (callBack.success == true) {
              // callBack.document = JSON.parse(callBack.data);
              // console.log('document', callBack.document);
            }
            else {
              if (callBack.message) {
                Swal.fire("Oop's", callBack.message, 'error')
              }
            }
          }


        }, error => {

        });


  }

  preview(item) {

    this.server.sendCommand(
      new LocalSignCommand(SignCommandType.ViewDocument, { fileName: item.fileSigned }), true).then(
        callBack => {


        }, error => {

        });


  }


  totalFileSigned = 0;
  uploadFileSigned() {

    this.server.sendCommand(
      new LocalSignCommand(SignCommandType.UploadDocument, this.command), false).then(
        callBack => {

          if (callBack) {
            if (callBack.success == true) {
              

              

            }
          }


        }, error => {

        });


  }



  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {

    }
  }

  close() {
    this.onCancelClick.emit(null);
  }


  docUploaded(event) {
    console.log('event: ', event);
  }

}
