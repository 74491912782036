import { SignatureModelType } from "../../enums/sig-enums";

export class PublicDocumentResumeViewModel {
    id: string;
    name: string;
    dateUploaded: Date;
    datePublish: Date | null;
    totalSigners: number;
    totalSignatures: number;
}



export interface DocumentSignModel {
    erpId:             string;
    publicDocumentId:  string;
    documentName:      string;
    signatureId:       string;
    userId:            string;
    documentNumber:    string;
    printProtocolPage: boolean;
    fields:            FieldInfo[];
}




export class UpdateSignerFieldsCommand{
    documentId: string;
    clearFields: boolean;
    fields: FieldInfo[];
}

export class FieldInfo {
    id: string;
    signatureId: string;
    method: any;
    page: number;
    y: number;
    x: number;
    width: number;
    height: number;
    addName: boolean;
    addDoc: boolean;
    addTitle: boolean;
    imgData: string;
}


export class SignatureModelViewModel {
    id: string;
    userId: string;
    title: string;
    imgExt: string;
    imgData: string;
    jsonField: string;
    urlImage: string;
    type: SignatureModelType
}